<template>
  <div class="livefeed-page">
    <template v-if="loading">...</template>
    <template v-else>
      <v-tabs v-model="tab" centered @change="onTabChange">
        <v-tab href="#latest">Latest</v-tab>
        <v-tab href="#photo">Photo</v-tab>
        <v-tab href="#video">Video</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item id="latest">
          <FeedPageContent
            type="latest"
            :feedData="latestFeedData"
            :subCategoryName="subCategoryName"
          />
        </v-tab-item>
        <v-tab-item id="photo">
          <FeedPageContent
            type="photo"
            :feedData="photoFeedData"
            :subCategoryName="subCategoryName"
          />
        </v-tab-item>
        <v-tab-item id="video">
          <FeedPageContent
            type="video"
            :feedData="videoFeedData"
            :subCategoryName="subCategoryName"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-layout justify-space-around v-if="hasMore">
        <v-progress-circular
          class="my-4 eof"
          size="50"
          width="3"
          indeterminate
        />
      </v-layout>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import inView from 'in-view';

import api from '@/services/api';

import FeedPageContent from '@/components/LiveFeed/FeedPageContent.vue';

export default {
  name: 'LiveFeed',
  components: {
    FeedPageContent,
  },
  data() {
    return {
      loading: true,
      loadingMore: false,
      hasMore: true,
      eofObj: null,
      tab: 'null',
      activeInterval: null,
      latestFeedData: [],
      photoFeedData: [],
      videoFeedData: [],
      pageNumber: 1,
      subCategoryName: {},
      nextCriteria: {},
      currentCriteria: {},
      currentFeedTime: '',
    };
  },
  async created() {
    await this.$store.dispatch('filter/initFilterCriteria').catch(() => {});
    this.loading = false;
  },
  beforeRouteLeave(to, from, next) {
    this.clearActiveInterval();
    if (this.eofObj) {
      // Do destroy;
      inView.clearHistory();
    }
    next();
  },
  methods: {
    ...mapActions({
      highlightKeywords: 'config/highlightKeywords',
    }),
    onTabChange(tab) {
      console.log(tab);
      this.clearActiveInterval();
      // NOTE consider clear other type of data ?
      this.pageNumber = 1;
      this.getFeedData();
      this.activeInterval = setInterval(() => {
        // console.log('RUNNING INTERVAL', this.tab);
        this.getNewerFeedData();
      }, 60000);
    },
    async getFeedData() {
      const data = JSON.parse(JSON.stringify(this.filterCriteria));
      data.paging = {
        page: this.pageNumber,
        recordPerPage: 25,
      };
      data.highlight = {
        enable: true,
      };
      data.sort = {
        field: 'created_at',
        direction: 'desc',
      };
      let result;
      if (this.tab === 'latest') {
        result = await api.getLatestFeed(data).catch(() => {});
      } else if (this.tab === 'photo') {
        result = await api.getImageFeed(data).catch(() => {});
      } else if (this.tab === 'video') {
        result = await api.getVideoFeed(data).catch(() => {});
      }
      if (result && result.message) {
        const {
          data,
          subCategoryName,
          nextCriteria,
          currentCriteria,
        } = result.message;
        this.subCategoryName = subCategoryName;
        this.nextCriteria = nextCriteria;
        this.currentCriteria = currentCriteria;
        this.currentFeedTime = dayjs().format('hh:mm:ss a');
        this.setCurrentTabFeedData(data);
        if (!this.eofObj) {
          // One time binding for loading more
          this.eofObj = inView('.eof').on('enter', this.onNextPage);
        }
      }
    },
    async getNewerFeedData() {
      let result;
      let oldData = this.currentFeedData;
      if (this.tab === 'latest') {
        result = await api.getLatestFeed(this.nextCriteria).catch(() => {});
      } else if (this.tab === 'photo') {
        result = await api.getImageFeed(this.nextCriteria).catch(() => {});
      } else if (this.tab === 'video') {
        result = await api.getVideoFeed(this.nextCriteria).catch(() => {});
      }
      if (result && result.message) {
        const { data, nextCriteria } = result.message;
        this.nextCriteria = nextCriteria;
        this.currentFeedTime = dayjs().format('hh:mm:ss a');
        // Concat data
        const newData = data.concat(oldData);
        this.setCurrentTabFeedData(newData);
      }
    },
    async onNextPage() {
      // From last element find from created_at -1
      if (!this.loadingMore) {
        this.loadingMore = true;
        const args = JSON.parse(JSON.stringify(this.currentCriteria));
        let currentData = this.currentFeedData;
        const currentLength = currentData.length;
        const lastItem = currentData[currentLength - 1];
        args.time = {
          untilDate: lastItem.info.created_at - 1,
        };
        let result;
        if (this.tab === 'latest') {
          result = await api.getOlderLatestFeed(args).catch(() => {});
        } else if (this.tab === 'photo') {
          result = await api.getOlderImageFeed(args).catch(() => {});
        } else if (this.tab === 'video') {
          result = await api.getOlderVideoFeed(args).catch(() => {});
        }
        if (result && result.message) {
          const { data, criteria } = result.message;
          this.currentCriteria = criteria;
          // Concat data
          const newData = currentData.concat(data);
          this.setCurrentTabFeedData(newData);
        }
        this.loadingMore = false;
      }
    },
    async setCurrentTabFeedData(data) {
      const highlightedData = await this.highlightKeywords({
        messageList: data,
      });
      if (this.tab === 'latest') {
        this.latestFeedData = highlightedData;
      } else if (this.tab === 'photo') {
        this.photoFeedData = highlightedData;
      } else if (this.tab === 'video') {
        this.videoFeedData = highlightedData;
      }
    },
    clearActiveInterval() {
      if (this.activeInterval) {
        clearInterval(this.activeInterval);
      }
    },
  },
  watch: {
    filterCriteria: {
      handler: 'getFeedData',
    },
  },
  computed: {
    ...mapState({
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
    currentFeedData() {
      if (this.tab) {
        switch (this.tab) {
          case 'latest':
            return this.latestFeedData;
          case 'photo':
            return this.photoFeedData;
          case 'video':
            return this.videoFeedData;
        }
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
